import React, {useState, useEffect} from "react";
import {Button, Container, Grid, Header, Icon, Message, Table} from "semantic-ui-react";
import MetadataTableRow from "../../MetadataTable/MetadataTableRow";
import SMPDataProvider from "../../../Services/SMPDataProvider";
import SMPMetadataSelector from "./SMPMetadataSelector";

const DATA_TYPES = [
    {key: "blank", text: "", value: ""},
    {key: "boolean", text: "Boolean", value: "boolean"},
    {key: "array", text: "Array", value: "array"},
    {key: "number", text: "Number", value: "number"},
    {key: "object", text: "Object", value: "object"},
    {key: "string", text: "String", value: "string"},
];

export default function SMPMetadataMasterDetailView(props) {
    const [metadata, setMetadata] = useState([]);
    const [selectedKey, setSelectedKey] = useState("");
    const [selectedMetadata, setSelectedMetadata] = useState([]);
    const [initialSelectedMetadata, setInitialSelectedMetadata] = useState([]);
    const [disableAddItem, setDisableAddItem] = useState(true);
    const [loadingMetadata, setLoadingMetadata] = useState(false);
    const [savingMetadata, setSavingMetadata] = useState(false);
    const [newClicked, setNewClicked] = useState(false);
    const [loadErrorMessage, setLoadErrorMessage] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(function updateDisableAddItem() {
        if (selectedMetadata.length < 1) {
            setDisableAddItem(true);
        } else {
            const lastItem = selectedMetadata[selectedMetadata.length - 1];
            setDisableAddItem(!lastItem.hasOwnProperty("key") || lastItem.key.length < 1);
        }
    }, [JSON.stringify(selectedMetadata), newClicked]);

    useEffect(function loadMetadata() {
        refreshMetadata();
    }, []);

    useEffect(function onClickCreateNew() {
        if (newClicked) {
            setSaveErrorMessage("");
            setSaveSuccessMessage("");
            setSelectedKey("");
            setSelectedMetadata([
                {key: "customer_id", type: "number", value: 0, lockType: true},
                {key: "prod_id", type: "number", value: 7, lockType: true},
                {key: "brand", type: "string", value: "", lockType: true},
                {key: "auth", type: "boolean", value: false, lockType: true},
                {key: "profile", type: "string", value: "", lockType: true},
                {key: "zeusHost", type: "string", value: "", lockType: true}
            ]);
            setInitialSelectedMetadata([]);
        }
    }, [newClicked]);

    useEffect(function onSelectedKeyUpdate() {
        if (newClicked) {
            setNewClicked(false);
        }
        if (selectedKey) {
            setSaveErrorMessage("");
            setSaveSuccessMessage("");
            let updatedSelectedMetadata = [];
            for (const metadatum of metadata) {
                if (metadatum._id === selectedKey) {
                    console.log(metadatum)
                    for (const key of Object.keys(metadatum)) {
                        updatedSelectedMetadata.push({
                            key,
                            value: metadatum[key] || "",
                            type: typeof metadatum[key],
                            lockType: ["auth", "brand", "customer_id", "prod_id", "profile", "zeusHost"].includes(key)
                        });
                    }
                }
            }
            setSelectedMetadata(Array.from(updatedSelectedMetadata));
            setInitialSelectedMetadata(Array.from(updatedSelectedMetadata));
        }

    }, [selectedKey, JSON.stringify(metadata)]);

    useEffect(function updateUnsavedChanges() {
        let updatedUnsavedChanges = JSON.stringify(initialSelectedMetadata) !== JSON.stringify(selectedMetadata);
        console.log("unsaved changes? ", initialSelectedMetadata, selectedMetadata);
        setUnsavedChanges(updatedUnsavedChanges);
    }, [JSON.stringify(initialSelectedMetadata), JSON.stringify(selectedMetadata)]);

    const updatePropertyKey = (index, key) => {
        if (index < selectedMetadata.length) {
            console.log(`SMPMetadataMasterDetailView.updatePropertyKey setting key to ${key} for index ${index}`);
            setSelectedMetadata(selectedMetadata.slice().map((property, i) => {
                return i !== index ? property : {
                    key,
                    value: property.value,
                    type: property.type,
                    lockType: property.lockType
                };
            }));
        } else {
            console.log(`SMPMetadataMasterDetailView.updatePropertyKey attempted to update property with index ${index} when there are only ${selectedMetadata.length} properties`);
        }
    };

    const updatePropertyValue = (index, value, propertyType) => {
        if (index < selectedMetadata.length) {
            const updatedSelectedMetadata = [];
            for (let i = 0; i < selectedMetadata.length; i++) {
                if (i === index) {
                    const currentType =
                        Array.isArray(selectedMetadata[i].value) ? "array" :
                            [true, false].includes(selectedMetadata[i].value) ? "boolean" :
                                typeof selectedMetadata[i] === "string" ? "string" :
                                    typeof selectedMetadata[i];
                    if (currentType === propertyType) {
                        console.log(`SMPMetadataMasterDetailView.updatePropertyValue setting property to ${JSON.stringify(value)} for key ${selectedMetadata[index].key}`);
                        updatedSelectedMetadata.push({
                            key: selectedMetadata[i].key,
                            value,
                            type: propertyType,
                            lockType: selectedMetadata[i].lockType
                        });
                    } else {
                        switch (propertyType) {
                            case "array": value = [value]; break;
                            case "object": value = {"": value}; break;
                            case "number": value = parseFloat(value); break;
                            case "boolean": value = value.toString() !== false; break;
                            case "string": value = value.toString(); break;
                            default: console.log("unexpected property type: ", propertyType);
                        }

                        console.log(`SMPMetadataMasterDetailView.updatePropertyValue updating property type to ${propertyType} from ${currentType}, setting property to ${JSON.stringify(value)} for key ${selectedMetadata[index].key}`);

                        updatedSelectedMetadata.push({
                            key: selectedMetadata[i].key,
                            value,
                            type: propertyType,
                            lockType: selectedMetadata[i].lockType
                        });
                    }
                } else {
                    updatedSelectedMetadata.push(selectedMetadata[i]);
                }
            }
            setSelectedMetadata(updatedSelectedMetadata);
        } else {
            console.log(`SMPMetadataMasterDetailView.updatePropertyValue attempted to update property with index ${index} when there are only ${selectedMetadata.length} properties`);
        }
    };

    const removeProperty = index => {
        const updatedSelectedMetadata = selectedMetadata.slice();
        updatedSelectedMetadata.splice(index, 1);
        console.log("updated metadata: ", updatedSelectedMetadata);
        setMetadata(updatedSelectedMetadata);
    };

    const addProperty = () => {
        setSelectedMetadata(selectedMetadata.concat({
            key: "",
            type: "string",
            value: "",
            lockType: false
        }));
    };

    const refreshMetadata = () => {
        setLoadingMetadata(true);
        setLoadErrorMessage("");
        SMPDataProvider.getMetadata().then(response => {
            if (response.hasOwnProperty("error")) {
                setMetadata([]);
                setSelectedMetadata([]);
                setInitialSelectedMetadata([]);
                console.error(response.error);
                setLoadErrorMessage("There has been an error loading the metadata.");
                return;
            }
            if (response.hasOwnProperty("metadata")) {
                setMetadata(response.metadata);
            }
            console.log(response);
        }).catch(error => {
            setLoadErrorMessage("There has been an error loading the metadata.");
            console.error(error);
        }).finally(() => {
            setLoadingMetadata(false);
        });
    };

    const saveMetadata = () => {
        const formattedMetadata = {};
        for (const metadatum of selectedMetadata) {
            formattedMetadata[metadatum.key] = metadatum.value;
        }

        console.log(formattedMetadata);

        setSavingMetadata(true);
        setSaveErrorMessage("");
        setSaveSuccessMessage("");
        setLoadErrorMessage("");
        SMPDataProvider.setMetadata(formattedMetadata).then(response => {
            console.log(response);
            if (response.message === "success") {
                setSaveSuccessMessage("The metadata was successfully updated.");
                return;
            }

            setSaveErrorMessage("There was an error saving this metadata.");
            console.error(response.error);
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error saving this metadata.");
        }).finally(() => {
            setSavingMetadata(false);
            refreshMetadata();
        });
    };

    const deleteMetadata = () => {
        const formattedMetadata = {};
        for (const metadatum of selectedMetadata) {
            formattedMetadata[metadatum.key] = metadatum.value;
        }

        console.log(formattedMetadata);

        setSavingMetadata(true);
        setSaveErrorMessage("");
        setSaveSuccessMessage("");
        setLoadErrorMessage("");
        SMPDataProvider.deleteMetadata(formattedMetadata).then(response => {
            console.log(response);
            if (response.message === "success") {
                setSaveSuccessMessage("The metadata was successfully deleted.");
                return;
            }

            setSaveErrorMessage("There was an error deleting this metadata.");
            console.error(response.error);
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There was an error deleting this metadata.");
        }).finally(() => {
            setSavingMetadata(false);
            refreshMetadata();
        });
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={3}>
                <SMPMetadataSelector
                    metadata={metadata}
                    loadingMetadata={loadingMetadata}
                    refreshMetadata={refreshMetadata}
                    selectedKey={selectedKey}
                    setSelectedKey={setSelectedKey}
                    newClicked={newClicked}
                    setNewClicked={setNewClicked}
                />
            </Grid.Column>
            <Grid.Column width={13}>
                <Grid>
                    <Grid.Column floated="right" width={8}>
                        <Container fluid textAlign="right" style={{visibility: Object.keys(selectedMetadata).length > 0 ? "visible": "hidden"}}>
                            <Button.Group>
                                <Button onClick={saveMetadata} primary disabled={!unsavedChanges}>Save</Button>
                                <Button.Or text=""/>
                                <Button color="red" onClick={deleteMetadata} disabled={!selectedKey || newClicked}>Delete</Button>
                            </Button.Group>
                        </Container>
                    </Grid.Column>
                </Grid>
                {
                    savingMetadata ?
                        <Message icon color="blue">
                            <Icon name="spinner" loading/>
                            <Message.Content>Saving Metadata...</Message.Content>
                        </Message> :
                    saveSuccessMessage ?
                        <Message icon color="green">
                            <Icon name="check circle" />
                            <Message.Content>{saveSuccessMessage}</Message.Content>
                        </Message> :
                    saveErrorMessage ?
                        <Message icon color="red">
                            <Icon name="warning" />
                            <Message.Content>{saveErrorMessage}</Message.Content>
                        </Message> : ""
                }
                {
                    loadErrorMessage ?
                        <Message icon color="red">
                            <Icon name="warning" />
                            <Message.Content>{loadErrorMessage}</Message.Content>
                        </Message> :
                    selectedMetadata.length < 1 ?
                        <Message icon color="yellow">
                            <Icon name="info" />
                            <Message.Content>
                                Please select metadata to continue.
                            </Message.Content>
                        </Message> :
                        <Table verticalAlign="top" compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1} />
                                    <Table.HeaderCell width={3}>Key</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Type</Table.HeaderCell>
                                    <Table.HeaderCell width={10}>Value</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    selectedMetadata.map((property, index) =>
                                        <MetadataTableRow
                                            key={`property-key-${index}`}
                                            index={index}
                                            property={property}
                                            updatePropertyKey={updatePropertyKey}
                                            updatePropertyValue={updatePropertyValue}
                                            removeProperty={removeProperty}
                                            DATA_TYPES={DATA_TYPES}
                                            newClicked={props.newClicked}
                                            duplicateClicked={props.duplicateClicked}
                                        />
                                    )
                                }
                            </Table.Body>
                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.Cell colSpan="5">
                                        <Button fluid onClick={() => addProperty()} disabled={disableAddItem}>Add Item</Button>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                }
            </Grid.Column>
        </Grid>
    )
};
