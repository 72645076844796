import React, { Component } from "react";
import {Tab} from "semantic-ui-react";
import S2STokenValidatorTab from "./S2STokenValidatorTab";
import SecureTabPane from "../../SecureTabPane";
import S2STokenGeneratorTab from "./S2STokenGeneratorTab";

export default class S2SToolsMasterDetailView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Tab
                panes={[
                    {
                        menuItem: {
                            key: "validator",
                            content: <label>Server-to-Server Token Validator</label>
                        },
                        render: () => <S2STokenValidatorTab />
                    },
                    {
                        menuItem: {
                            key: "generator",
                            content: <label>Server-to-Server Token Generator</label>
                        },
                        render: () =>
                            <SecureTabPane
                                permissionsRequired={["urn:all:aspen-power"]}
                                component={<S2STokenGeneratorTab toast={this.props.toast}/>}
                                authenticated={this.props.authenticated}
                                checkIfAuthorized={this.props.checkIfAuthorized}
                                user={this.props.user}
                                service={this.props.service}
                                module={this.props.module}
                                permissions={this.props.permissions}
                                userPermissions={this.props.userPermissions}
                            />
                    }
                ]}
            />
        );
    }
}
