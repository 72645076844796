import React, {useState, useEffect} from "react";
import {init, getClusterConfigs, scaleCluster} from "../../../Services/ScaleratorDataProvider";
import {Button, Dropdown, Form, Grid, Icon, Input, Message} from "semantic-ui-react";
import ReactTable from "react-table-v6";

export default function ScaleratorHandler(props) {
    const [clusterConfigs, setClusterConfigs] = useState([]);
    const [clusterSelection, setClusterSelections] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedCluster, setSelectedCluster] = useState(null);
    const [isScaling, setIsScaling] = useState(false);

    init(process.env.SCALERATOR_URL);

    useEffect(() => {
        getClusterConfigs().then(resp => {

            if (resp.status === 200) {
                const clusterSelections = resp.data.map((cluster) => {
                    return {
                        key: cluster.id,
                        text: cluster.description,
                        value: cluster.id
                    }
                });

                setClusterConfigs(resp.data);
                setClusterSelections(clusterSelections);
            } else {
                setClusterConfigs([]);
                setClusterSelections([]);
                props.toast("Error", `. Received ${resp.status} error getting cluster configs at ${process.env.SCALERATOR_URL}.`)
            }
        });
    }, []);

    function handleScaleButtonClick() {
        setIsScaling(true);
        scaleCluster(selectedCluster).then(resp => {
            if(resp.status === 200) {
                props.toast("Success", `Scaling ${selectedCluster.cluster.clusterName}`);
            } else {
                props.toast("Error", resp);
            }
        }).finally(() => {
            setIsScaling(false);
        });
    }

    return (<Grid>
        <Grid.Column width={6} className="EditorContainer">
            <Grid.Row>
                <Form>
                    <Form.Field
                        label="Select an EC2 Cluster Action"
                        control={Dropdown}
                        selection
                        options={clusterSelection}
                        value={selectedCluster && selectedCluster.id}
                        onChange={(event, {value})=>{
                            const selectedCluster = clusterConfigs.find((cluster) => {
                                return cluster.id === value;
                            });

                            const services = selectedCluster.cluster.services;
                            console.log(selectedCluster)
                            const serviceKeys = Object.keys(services);
                            const servicesTableData = serviceKeys.map(service => {
                                return {
                                    cluster: selectedCluster.cluster.clusterName,
                                    service: service,
                                    max_capacity: services[service].max_capacity,
                                    min_capacity: services[service].min_capacity,
                                    desired_count: services[service].desired_count
                                }
                            });

                            setTableData(servicesTableData);
                            setSelectedCluster(selectedCluster);
                        }}
                    >
                    </Form.Field>
                </Form>
            </Grid.Row>
            <Button onClick={handleScaleButtonClick} disabled={!selectedCluster}><Icon name={isScaling ? "spinner" : "sliders"} rotated="clockwise" loading={isScaling} /> Scale Cluster</Button>
        </Grid.Column>
        <Grid.Column width={10} className="detailsContainer">
            <Form>
                <Form.Field
                    label="Config ID"
                    control={Input}
                    value={selectedCluster ? selectedCluster.id: ""}
                />
                <Form.Field
                    label="Description"
                    control={Input}
                    value={selectedCluster ? selectedCluster.description: ""}
                />
            </Form>
            <ReactTable
                data={tableData}
                columns={[
                    {Header: "Cluster", accessor: "cluster"},
                    {Header: "Service", accessor: "service"},
                    {Header: "Max Capacity", accessor: "max_capacity"},
                    {Header: "Min Capacity", accessor: "min_capacity"},
                    {Header: "Desired Count", accessor: "desired_count"}
                ]}
            />
        </Grid.Column>
    </Grid>)
}