import React, {useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import ScaleratorHandler from "./ScaleratorHandler";


export default function ScaleratorAdminMasterDetailsView(props) {
    const [userCanEdit, setUserCanEdit] = useState(false);

    useEffect(function updateUserCanEdit() {
        let updatedUserCanEdit = props.user.authz.includes("urn:all:aspen-power");

        if (!updatedUserCanEdit) {
            for (const permission of props.permissions) {
                const serviceMatches = permission.service === props.service;
                const moduleMatches = ["all", permission.module].includes(permission.module);
                const roleMatches = ["editor", "admin"].includes(permission.role);

                console.log(`Scalerator update permissions. Service matches: ${serviceMatches}, module matches ${moduleMatches}, role matches ${roleMatches}`);

                if (serviceMatches && moduleMatches && roleMatches) {
                    updatedUserCanEdit = true;
                    break;
                }
            }
        }
        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.permissions), JSON.stringify(props.user)]);

    return (<Grid>
        <Grid.Column className='masterContainer'>
            <SecureTabPane
                authenticated={props.authenticated}
                checkIfAuthorized={props.checkIfAuthorized}
                service={props.service}
                permissions={props.permissions}
                module={props.module}
                userPermissions={props.userPermissions}
                user={props.user}
                loadingPermissions={props.loadingPermissions}
                component={<ScaleratorHandler
                    toast={props.toast}
                    user={props.user}
                service={props.service}
                module={props.module}
                permissions={props.permissions}
                userCanEdit={userCanEdit}/>}
            />
        </Grid.Column>
    </Grid>)
}