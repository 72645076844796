import React, {useState, useEffect} from "react";
import {Button, Form, Grid, Input, Select} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SMPDataProvider from "../../../Services/SMPDataProvider";
import SMPAuditSubcomponent from "./SMPAuditSubcomponent";

SMPDataProvider.init({baseURL: process.env.SMP_URL});

const TYPES = {
    "all": "All",
    "startover": "Startover",
    "vod": "VOD"
}
const TYPE_OPTIONS = Object.keys(TYPES).map(type => {
    return {key: type, text: TYPES[type], value: type};
});

const STATUSES = {
    "all": "All",
    "received": "Received",
    "completed": "Completed",
    "verified": "Verified",
    "failed": "Failed"
};

const STATUS_OPTIONS = Object.keys(STATUSES).map(status => {
    return {key: status, text: STATUSES[status], value: status};
});

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};


export default function SMPAuditMasterDetailView(props) {
    const [data, setData] = useState([]);
    const [airingId, setAiringId] = useState("");
    const [selectedType, setSelectedType] = useState("all");
    const [foreignId, setForeignId] = useState("");
    const [zeusJobId, setZeusJobId] = useState("");
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState("all");
    const [loading, setLoading] = useState(false);

    useEffect(function initializeData() {
        performSearch();
    }, []);

    const performSearch = () => {
        setLoading(true);
        SMPDataProvider.getByCriteria(airingId, selectedType, foreignId, start, end, selectedStatus, zeusJobId).then(response => {
            if (Array.isArray(response.messages)) {
                setData(response.messages);
            } else {
                setData([]);
            }
        }).catch(error => {
            console.error(error);
            setData([]);
        }).finally(() => {
            setLoading(false);
        });
    };

    const clearFilters = () => {
        setAiringId("");
        setSelectedType("");
        setForeignId("");
        setZeusJobId("");
        setStart(null);
        setEnd(null);
        setSelectedStatus("");
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={3} style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                <Form>
                    <Form.Field>
                        <Button color="blue" onClick={performSearch} fluid>Search</Button>
                    </Form.Field>
                    <Form.Field>
                        <Form.Field>
                            <Button fluid color="black" onClick={clearFilters}>Clear Filters</Button>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field
                        label="Converted Airing ID"
                        control={Input}
                        value={airingId}
                        onChange={(event, {value}) => setAiringId(value)}
                    />
                    <Form.Field
                        label="Type"
                        control={Select}
                        value={selectedType}
                        options={TYPE_OPTIONS}
                        onChange={(event, {value}) => setSelectedType(value)}
                    />
                    <Form.Field
                        label="Status"
                        control={Select}
                        value={selectedStatus}
                        options={STATUS_OPTIONS}
                        onChange={(event, {value}) => setSelectedStatus(value)}
                    />
                    <Form.Field
                        label="Foreign ID"
                        control={Input}
                        value={foreignId}
                        onChange={(event, {value}) => setForeignId(value)}
                    />
                    <Form.Field
                        label="Zeus Job ID"
                        control={Input}
                        value={zeusJobId}
                        onChange={(event, {value}) => setZeusJobId(value)}
                    />
                    <Form.Field>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <label>Start Time</label>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                id="start"
                                value={start}
                                onChange={setStart}
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                    <Form.Field width={16}>
                        <label>End Time</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                id="end"
                                value={end}
                                onChange={setEnd}
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column width={13} className="masterContainer" style={{overflowY: "auto", overflowX: "hidden"}}>
                <ReactTable
                    data={data}
                    filterable={true}
                    defaultPageSize={100}
                    loading={loading}
                    className="-highlight"
                    style={{height: "85vh"}}
                    columns={
                        [
                            {Header: "Airing ID", accessor: "convertedAiringId"},
                            {Header: "Foreign ID", accessor: "foreignId"},
                            {
                                Header: "Type",
                                accessor: "type",
                                Cell: row => TYPES.hasOwnProperty(row.original.type) ? TYPES[row.original.type] : row.original.type
                            },
                            {
                                Header: "Status",
                                accessor: "status",
                                Cell: row => STATUSES.hasOwnProperty(row.original.status) ? STATUSES[row.original.status] : row.original.status
                            },
                            {
                                Header: "Zeus Job ID",
                                accessor: "zeusJobId"
                            },
                            {
                                "Header": "Inband Timestamp",
                                accessor: "inbandTimestamp",
                                Cell: row => <span>{new Date(row.original.inbandTimestamp).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>
                            },
                            {
                                Header: "Last Updated",
                                accessor: "last_updated",
                                Cell: row => <span>{new Date(row.original.last_updated * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>
                            },
                            {accessor: "message", show: false},
                            {accessor: "zeusPayload", show: false},
                            {accessor: "zeusResponse", show: false},
                            {accessor: "error", show: false}
                        ]
                    }
                    defaultSorted={[
                        {id: "last_updated", desc: true}
                    ]}
                    SubComponent={row =>
                        <SMPAuditSubcomponent
                            zeusPayload={row.original.zeusPayload}
                            zeusResponse={row.original.zeusResponse}
                            message={row.original.message}
                            error={row.original.error}
                        />
                    }
                />
            </Grid.Column>
        </Grid>
    )
};
