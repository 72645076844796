import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Input, Item, Menu, Message} from "semantic-ui-react";

export default function SMPMetadataSelector(props) {
    const [filter, setFilter] = useState("");
    const [filteredMetadata, setFilteredMetadata] = useState([]);

    useEffect(function updateFilteredMetadata() {
        const updatedFilteredMetadata = [];
        for (const metadatum of props.metadata) {
            if (metadatum.hasOwnProperty("brand") && metadatum.brand.includes(filter)) {
                updatedFilteredMetadata.push(Object.assign({}, metadatum));
            }
        }
        setFilteredMetadata(updatedFilteredMetadata);
    }, [JSON.stringify(props.metadata), filter]);

    return (
        <Grid>
            <Grid.Column width={16}>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon secondary onClick={() => props.setNewClicked(true)} fluid><Icon name="plus"/> Create New</Button>
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon onClick={() => props.refreshMetadata()} fluid><Icon name="refresh"/> Refresh</Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="&nbsp;"
                            control={Input}
                            onChange={(event, {value}) => setFilter(value)}
                            value={filter}
                            fluid
                            icon="filter"
                            width={11}
                            placeholder="Filter Settings"
                        />
                    </Form.Group>
                </Form>
                <Container style={{maxHeight: "72.5vh", overflowY: "auto"}}>
                    {
                        props.loadingMetadata ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading/>
                                <Message.Content>Loading SMP Metadata...</Message.Content>
                            </Message> :
                            <Menu vertical fluid>
                                {
                                    filteredMetadata.length < 1 ?
                                        <Message icon color="yellow">
                                            <Icon name="warning"/>
                                            <Message.Content>No SMP metadata settings match your current filter.</Message.Content>
                                        </Message> :
                                        filteredMetadata.map(metadatum =>
                                            <Menu.Item
                                                active={props.selectedKey === metadatum._id}
                                                key={JSON.stringify(metadatum)}
                                                onClick={
                                                    () => {
                                                        console.log(metadatum);
                                                        props.setSelectedKey(metadatum._id);
                                                    }
                                                }
                                                fluid
                                            >
                                                <Item.Group>
                                                    <Item>
                                                        <Item.Content>
                                                            <Item.Header as="a">{metadatum.brand}</Item.Header>
                                                            <Item.Description>
                                                                <b>Customer ID</b>: {metadatum.customer_id}
                                                                <br />
                                                                <b>Prod ID</b>: {metadatum.prod_id}
                                                            </Item.Description>
                                                        </Item.Content>
                                                    </Item>
                                                </Item.Group>
                                            </Menu.Item>
                                        )
                                }
                            </Menu>
                    }
                </Container>
            </Grid.Column>
        </Grid>
    );
};
