import React, {Component} from "react";
import {Tab, Grid, TextArea, Form, Input, Message} from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import jwt from "jsonwebtoken";

export default class S2STokenValidatorTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jwt: "",
            token: {},
            header: {},
            payload: {},
            secret: "",
            publicKey: "",
            headerErrors: [],
            payloadErrors: [],
            secretErrors: [],
            jwtValidationError: null
        };

        this.parseJWT = this.parseJWT.bind(this);
        this.changeSecret = this.changeSecret.bind(this);
        this.verifySecret = this.verifySecret.bind(this);
    }

    parseJWT = (event, {value}) => {
        this.setState({jwt: value, jwtValidationError: null, headerErrors: [], payloadErrors: [], secretErrors: []}, () => {
            console.log("(S2STokenValidatorTab.parseJWT) jwt: ", value);
            new Promise(resolve => {
                resolve(jwt.decode(this.state.jwt, {complete: true}));
            }).then(token => {
                console.log("(S2STokenValidatorTab.parseJWT) result", token);
                let {header, payload} = token;
                let errors = {
                    headerErrors: [],
                    payloadErrors: []
                };
                this.setState({header, payload});
                if (header) {
                    console.log(token.header);
                    if (!header.kid) {
                        console.log('The header is missing the "kid" (Key ID).');
                        errors.headerErrors.push('The header is missing the "kid" (Key ID).');
                    }
                    if (!header.ver) {
                        console.log('S2S Tokens are required to have a "ver" in the header. It should equal 1 if it uses a shared secret, or 2 if it uses an RSA key.');
                        errors.headerErrors.push('S2S Tokens are required to have a "ver" in the header. It should equal 1 if it uses a shared secret, or 2 if it uses an RSA key.');
                    } else if (![1, 2].includes(header.ver)) {
                        console.log(`${header.ver} is not a valid "ver" value.`);
                        errors.headerErrors.push(`${header.ver} is not a valid "ver" value.`);
                    } else if ((header.ver === 1 || header.version === 1) && header.alg !== "HS512") {
                        console.log('Version 1 S2S Tokens are required to have "alg":"HS512" in the header.');
                        errors.headerErrors.push('Version 1 S2S Tokens are required to have "alg":"HS512" in the header.');
                    } else if (header.ver === 2 && header.alg !== "RS512") {
                        console.log('Version 2 S2S Tokens are required to have "alg":"RS512" in the header.');
                        errors.headerErrors.push('Version 2 S2S Tokens are required to have "alg":"RS512" in the header.');
                    }
                    if (header.typ !== "JWT") {
                        console.log('The header should contain "typ":"JWT".')
                        errors.headerErrors.push('The header should contain "typ":"JWT".');
                    }
                }
                if (payload) {
                    console.log(token.payload);
                    if (!payload.iss) {
                        console.log("The payload is missing an \"iss\", which should be an app ID.")
                        errors.payloadErrors.push("The payload is missing an \"iss\", which should be an app ID.");
                    }
                    if (!payload.exp && payload.exp !== 0) {
                        console.log("The payload is missing an expiration timestamp, in seconds since epoch, with the key \"exp\".");
                        errors.payloadErrors.push("The payload is missing an expiration timestamp, in seconds since epoch, with the key \"exp\".");
                    } else if (payload.exp < Math.floor(Date.now() / 1000)) {
                        console.log("This JWT is expired.");
                        errors.payloadErrors.push("This JWT is expired.");
                    } else if (payload.exp > 9999999999) {
                        console.log("This token's \"exp\" appears to be in milliseconds. It should be in seconds.");
                        errors.payloadErrors.push("This token's \"exp\" appears to be in milliseconds. It should be in seconds.");
                    }
                    if (!payload.iat && payload.iat !== 0) {
                        console.log("The payload is missing the generation timestamp, in seconds since epoch, with the key \"iat\".");
                        errors.payloadErrors.push("The payload is missing the generation timestamp, in seconds since epoch, with the key \"iat\".");
                    }  else if (payload.iat > 9999999999) {
                        console.log("This token's \"iat\" appears to be in milliseconds. It should be in seconds.");
                        errors.payloadErrors.push("This token's \"iat\" appears to be in milliseconds. It should be in seconds.");
                    } else if (payload.iat > Math.floor(Date.now() / 1000)) {
                        console.log("This token is not valid yet; its \"iat\" is in the future.");
                        errors.payloadErrors.push("This token is not valid yet; its \"iat\" is in the future.");
                    }

                    if (!payload.endpoint) {
                        console.log("The payload is missing an \"endpoint\" which corresponds to the path in the web application.");
                        errors.payloadErrors.push("The payload is missing an \"endpoint\" which corresponds to the path in the web application.")
                    }

                    if (typeof payload.payload !== "object") {
                        console.log("Either the payload is missing a payload, or the payload is not a JSON object.");
                        errors.payloadErrors.push("Either the payload is missing a payload, or the payload is not a JSON object.");
                    }
                }
                return errors;
            }).then(errors => {
                this.setState({headerErrors: errors.headerErrors});
                this.setState({payloadErrors: errors.payloadErrors});
            }).then(this.verifySecret).catch(error => {
                console.error(error);
                this.setState({jwtValidationError: "This token could not be decoded.", header: {}, payload: {}});
            });
        });
    }

    changePublicKey = event => {
        this.setState({publicKey: event.target.value, secretErrors: []}, this.verifySecret);
    }

    changeSecret = (event, {value}) => {
        this.setState({secret: value, secretErrors: []}, this.verifySecret);
    }

    verifySecret = () => {
        let version = this.state.header.ver || this.state.header.version;
        const verificationOptions = {
            ignoreExpiration: true
        };
        if (version && [1, 2].includes(version)) {
            new Promise(resolve => {
                if (version === 1) {
                    resolve(jwt.verify(this.state.jwt, `${this.state.secret}${this.state.payload.iat}`, verificationOptions));
                } else if (version === 2) {
                    resolve(jwt.verify(this.state.jwt, this.state.publicKey, verificationOptions));
                }
            }).then(success => {
                console.log(success);
            }).catch(async error => {
                let secretErrors = [];
                console.log(error);
                if (version === 1) {
                    await new Promise(resolve => {
                        resolve(jwt.verify(this.state.jwt, this.state.secret, verificationOptions));
                    }).then(() => {
                        console.log("This V1 JWT did not have the 'iat' appended to the secret.");
                        secretErrors.push("This V1 JWT did not have the 'iat' appended to the secret.");
                    }).catch(() => {
                        console.log("This JWT's shared secret is wrong.");
                        secretErrors.push("This JWT's shared secret is wrong.");
                    });
                } else {
                    console.log("This JWT's public key is wrong.");
                    secretErrors.push("This JWT's public key is wrong.");
                }
                console.log(secretErrors);
                this.setState({secretErrors});
            });
        }
    }

    render() {
        return (
            <Tab.Pane>
                <Grid>
                    <Grid.Column width={8}>
                        <Form>
                            {
                                this.state.jwt && !this.state.jwtValidationError && this.state.payloadErrors.length < 1 &&
                                this.state.headerErrors.length < 1 && this.state.secretErrors.length < 1 ?
                                    <Message positive attached="bottom">
                                        <Message.Header>JWT Verification</Message.Header>
                                        <Message.Content>
                                            This JWT is valid.
                                        </Message.Content>
                                    </Message> : ""
                            }
                            {
                                this.state.jwtValidationError !== null ?
                                    <Message negative attached="bottom">
                                        <Message.Header>JWT Verification Error</Message.Header>
                                        <Message.Content>
                                            {this.state.jwtValidationError}
                                        </Message.Content>
                                    </Message> : ""
                            }
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="validator-jwt-control-textarea"
                                    label="JWT to Validate"
                                    control={TextArea}
                                    rows={20}
                                    placeholder="Paste JWT Here to Validate"
                                    value={this.state.jwt}
                                    onChange={this.parseJWT}
                                    style={{ minHeight: "100%" }}
                                />
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="validator-jwt-header-control-input"
                                    label="Header"
                                    control={TextareaAutosize}
                                    minRows={5}
                                    maxRows={10}
                                    value={JSON.stringify(this.state.header, null, 4)}
                                    readOnly
                                />
                            </Form.Group>
                            {
                                this.state.headerErrors.length > 0 ?
                                    <Message negative attached="bottom">
                                        <Message.Header>Errors in Header</Message.Header>
                                        <Message.List>
                                            {this.state.headerErrors.map(error => <Message.Item
                                                key={error}>{error}</Message.Item>)}
                                        </Message.List>
                                    </Message> : ""
                            }
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="validator-jwt-payload-control-input"
                                    label="Payload"
                                    control={TextareaAutosize}
                                    minRows={5}
                                    maxRows={10}
                                    value={JSON.stringify(this.state.payload, null, 4)}
                                    readOnly
                                />
                            </Form.Group>
                            {
                                this.state.payloadErrors.length > 0 ?
                                    <Message negative attached="bottom">
                                        <Message.Header>Errors in Payload</Message.Header>
                                        <Message.List>
                                            {this.state.payloadErrors.map(error => <Message.Item
                                                key={error}>{error}</Message.Item>)}
                                        </Message.List>
                                    </Message> : ""
                            }
                            {
                                this.state.header.ver === 2 ?
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            id="validator-jwt-public-key-textarea"
                                            label="Public Key"
                                            control={TextareaAutosize}
                                            value={this.state.publicKey}
                                            onChange={this.changePublicKey}
                                        />
                                    </Form.Group> :
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            id="validator-jwt-secret-input"
                                            label="Secret"
                                            control={Input}
                                            value={this.state.secret}
                                            onChange={this.changeSecret}
                                        />
                                    </Form.Group>
                            }
                            {
                                this.state.secretErrors.length > 0 ?
                                    <Message negative attached="bottom">
                                        <Message.Header>Verification Errors</Message.Header>
                                        <Message.List>
                                            {this.state.secretErrors.map(error => <Message.Item
                                                key={error}>{error}</Message.Item>)}
                                        </Message.List>
                                    </Message> : ""
                            }
                        </Form>
                    </Grid.Column>
                </Grid>
            </Tab.Pane>
        );
    }
}
