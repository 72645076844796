import React, {useState, useEffect} from "react";
import {Grid, Segment, Tab} from "semantic-ui-react";

export default function SMPAuditSubcomponent(props) {
    const [isError, setIsError] = useState(false);
    const [isZeusPayload, setIsZeusPayload] = useState(false);
    const [isZeusResponse, setIsZeusResponse] = useState(false);

    useEffect(function updateIsError() {
        setIsError(!!props.error);
    }, [props.error]);

    useEffect(function updateIsZeusPayload() {
        setIsZeusPayload(!!props.zeusPayload);
    }, [props.zeusPayload]);

    useEffect(function isZeusResponse() {
        setIsZeusResponse(!!props.zeusResponse);
    }, [props.zeusResponse]);

    return (
        <Grid>
            <Grid.Column width={16}>
                <Tab
                    fluid
                    styled
                    defaultActiveIndex={[0]}
                    exclusive={false}
                    panes={[
                        {
                            menuItem: "iSP Payload",
                            render: () => <Segment><pre>{JSON.stringify(props.message, null, 4)}</pre></Segment>
                        },
                        isZeusPayload ? {
                            menuItem: "Zeus Payload",
                            render: () =>
                                <Segment><pre>{JSON.stringify(props.zeusPayload, null, 4)}</pre></Segment>
                        } : "",
                        isZeusResponse ? {
                            menuItem: "Zeus Response",
                            render: () =>
                                <Segment><pre>{JSON.stringify(props.zeusResponse, null, 4)}</pre></Segment>
                        } : "",
                        isError ?
                            {
                                menuItem: "Errors",
                                render: () => <Segment><pre>{JSON.stringify(props.error, null, 4)}</pre></Segment>
                            } : ""
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
